<template>
  <div>
    <br>
    <br>
    <div class="all-page-loader"
      v-if="isLoading">
      <div class="page-loader"></div>
    </div>
    <div class="container" style="padding-left: 10px;padding-right: 10px;"
      v-if="notifi.show || notifi.isClose">
      <div class="notification is-danger">
        <button class="delete" v-if="notifi.isClose" @click="notifi.show = false"></button>
        {{notifi.msg}}
      </div>
    </div>
    <div class="container" style="padding-left: 10px;padding-right: 10px;"
      v-if="notifi.show == false || notifi.isClose">
      
      <div>
        <div><span class="title is-4">- Local</span></div>
        <div><span class="title is-3">{{escola.nome}}</span></div>
      </div>
      <div class="">
        <!-- <div>
          <button class="button is-link"
            @click="clickBtnAddComputador">
            Adicionar Computador
          </button>
        </div> -->
        <br>
        <div>
          <button class="button is-link"
            @click="clickBtnAddEquipamento">
            Adicionar Equipamento
          </button>
        </div>
        <br>
        <div class="">
          <span class="subtitle is-4">
          - Equipamentos de TI
          </span>
        </div>
        <br>
        <div>
          <div class="tabs is-boxed">
            <ul>
              <li :class="{'is-active':  this.isTab == 1}"
                @click="clickTab(1)">
                <a>
                  <span>
                    Computadores 
                    <span class="tag is-danger">
                      {{this.listComputadoresCount}}
                    </span>
                  </span>
                </a>
              </li>
              <li :class="{'is-active': this.isTab == 0}"
                @click="clickTab(0)">
                <a>
                  <span>
                    Todos Equipamentos
                    <span class="tag is-danger">
                      {{this.listEquipamentosCount}}
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="content-tab">
          asdsad
        </div> -->


      </div>
      <br>

      <!-- TAB EQUIPAMENTOS -->
      <div class="content-tab"
        v-show="this.isTab == 0">
        <div class="columns is-multiline">
          <div class="column is-3" 
            v-for="(equipamento, index) in listEquipamentos" 
            :key="index">
            <div class="box">
              <p>ID: {{equipamento.idEquipamento}}</p>
              <p>Patrimônio: {{equipamento.patrimonio}}</p>
              <p>
                Tipo:
                <span class="txtTipo" v-if="equipamento.idTipo == 1">Gabinete</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 2">Monitor</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 3">Notebook</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 4">Impressora</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 5">Roteador</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 6">Switch</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 7">Access Point</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 8">Nobreaks</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 9">Estabilizador</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 10">COMPUTADOR</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 11">Projetor</span>
                <span class="txtTipo" v-else-if="equipamento.idTipo == 12">Computador Embutido (projetor + computador)</span>
                <span v-else>Tipo não cadastrado</span>
              </p>
              <p>Localização: {{equipamento.localizacao}}</p>
              <p>
                <span class="has-text-success" 
                  v-if="equipamento.isOperante == 1">
                  Operante
                </span>
                <span class="has-text-danger"
                  v-if="equipamento.isOperante == 2">
                  Não Operante
                </span>
                <span class="has-text-warning"
                  v-if="equipamento.isOperante == 3">
                  Sim, Mas com problema
                </span>
              </p>
              <p v-if="equipamento.idPertencedor != null">
                <span>Associado no ID: {{equipamento.idPertencedor}}</span>
              </p>
              <p>
                <button class="button is-warning is-small"
                  @click="clickBtnEditarInformacoes(equipamento.idEquipamento)">
                  Editar informaçoes
                </button>
              </p>
            </div>
          </div>  
          
        </div>
      </div>

      <!-- TAB COMPUTADORES -->
      <div class="content-tab"
        v-show="this.isTab == 1">
        <div class="columns is-multiline">
          <div class="column is-3" 
            v-for="(equipamento, index) in listComputadores" 
            :key="index">
            <div class="box">
              <p>Patrimônio: {{equipamento.patrimonio}}</p>
              <p>
                Tipo:
                <span v-if="equipamento.idTipo == 1">Gabinete</span>
                <span v-else-if="equipamento.idTipo == 2">Monitor</span>
                <span v-else-if="equipamento.idTipo == 3">Notebook</span>
                <span v-else-if="equipamento.idTipo == 4">Impressora</span>
                <span v-else-if="equipamento.idTipo == 5">Roteador</span>
                <span v-else-if="equipamento.idTipo == 6">Switch</span>
                <span v-else-if="equipamento.idTipo == 7">Access Point</span>
                <span v-else-if="equipamento.idTipo == 8">Nobreaks</span>
                <span v-else-if="equipamento.idTipo == 9">Estabilizador</span>
                <span v-else-if="equipamento.idTipo == 10">COMPUTADOR</span>
                <span v-else>Tipo não cadastrado</span>
              </p>
              <p>Localização: {{equipamento.localizacao}}</p>
              <p>
                <span class="has-text-success" 
                  v-if="equipamento.isOperante == 1">
                  Operante
                </span>
                <span class="has-text-danger"
                  v-if="equipamento.isOperante == 2">
                  Não Operante
                </span>
                <span class="has-text-warning"
                  v-if="equipamento.isOperante == 3">
                  Sim, Mas com problema
                </span>
              </p>
              <p>
                <button class="button is-warning is-small"
                  @click="clickBtnEditarInformacoes(equipamento.idEquipamento)">
                  Editar informaçoes
                </button>
              </p>
            </div>
          </div>  
          
        </div>
      </div>
      <br>


    </div>
  </div>
</template>

<script>

  import Request from '@/utils/Request.js';

  export default {

    data() {
      return {
        isTab: 1,
        isLoading: false,
        notifi: {
          isClose: false,
          show: false,
          type: 'danger',
          msg: ''
        },
        escola: {},
        listEquipamentosCount: 0,
        listEquipamentos: [],
        listComputadoresCount: 0,
        listComputadores: [],
        listTipos: [
          {id: 1, nome: 'Gabinete'},
          {id: 2, nome: 'Monitor'},
          {id: 3, nome: 'Notebook'},
          {id: 4, nome: 'Impressora'},
          {id: 5, nome: 'Roteador'},
          {id: 6, nome: 'Switch'},
          {id: 7, nome: 'Access Point'},
          {id: 8, nome: 'Nobreaks'},
          {id: 9, nome: 'Estabilizador'},
        ]
      }
    },

    mounted() {
      this.isLoading = true;
      let idEscola = this.getUrlIdEscola();
      this.sendGetEscolaByID(idEscola);
    },

    methods: {
      clickTab(numTab){
        this.isTab = numTab;
      },
      getUrlIdEscola(){
        return this.$route.params.idEscola;
      },
      sendGetEscolaByID(idEscola){
        const success = (response) => {
          this.escola = response.data;
          this.sendGetAllEquipamentosEscola(idEscola);
          this.sendGetAllComputadoresEscola(idEscola);
          this.isLoading = false;
        }
        const error = (error) => {
          this.isError = true;
          this.listEquipamentos = [];
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = false;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Local não encontrado. Verifique se o endereço da URL esta correto';
        }
        const url = Request.mountUrl2('/escolas/'+idEscola);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      getTipoNome(numero){
        if(numero!=undefined){
          return this.listTipos[numero];
        }
      },
      clickBtnEditarInformacoes(idEquipamento){
        let idEscola = this.getUrlIdEscola();
        this.$router.push('/smed/'+idEscola+'/equipamento/'+idEquipamento);
      },
      clickBtnAddEquipamento(){
        let idEscola = this.getUrlIdEscola();
        this.$router.push('/smed/'+idEscola+'/equipamento');
      },
      clickBtnAddComputador(){
        let idEscola = this.getUrlIdEscola();
        this.$router.push('/smed/'+idEscola+'/computador');
      },
      sendGetAllEquipamentosEscola(idEscola){
        this.isLoading = true;
        const success = (response) => {
          this.listEquipamentosCount = response.data.count;
          this.listEquipamentos = response.data.list;
          this.isLoading = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro ao buscar lista de equipamentos';
        }
        const url = Request.mountUrl2('/equipamentos?idEscola='+idEscola+'&_orderBy=idEquipamento&_order=desc&_limit=500');
        Request.get(url)
          .then(success)
          .catch(error);
      },
      sendGetAllComputadoresEscola(idEscola){
        this.isLoading = true;
        const success = (response) => {
          this.listComputadoresCount = response.data.count;
          this.listComputadores = response.data.list;
          this.isLoading = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro ao buscar lista de equipamentos';
        }
        const url = Request.mountUrl2('/equipamentos?idEscola='+idEscola+'&_orderBy=idEquipamento&_order=desc&idTipo=10&_limit=50');
        Request.get(url)
          .then(success)
          .catch(error);
      },
    },
    
  }
</script>

<style lang="scss" scoped>

.content-tab{
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin-top: -25px;
  padding: 15px;
}

.txtTipo{
  text-transform: uppercase;
  font-weight: bold;
  text-overflow: ellipsis;

}

</style>